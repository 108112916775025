import React from 'react';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { LinkBanner } from '@naf/banner';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { TextAndImageBlockType } from '../../../../../types/TextAndImageBlockType';
import { useCloudinary } from '../../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../../hooks/useDevicePixelRatio';
import BlockContent from '../../block-content/BlockContent';

export const TextAndImageBlock = ({ block }: { block: TextAndImageBlockType }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const cldImage = block?.image?.publicId
    ? cld.image(block?.image?.publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto')
    : undefined;

  const advancedImage = cldImage ? (
    <AdvancedImage
      style={{ maxWidth: '100%' }}
      alt={block?.image?.alt || block?.image?.altText}
      cldImg={cldImage}
      plugins={[
        lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
        responsive({ steps: 200 }),
        placeholder({ mode: 'blur' }),
      ]}
    />
  ) : undefined;

  return (
    <LinkBanner
      title={block.title}
      imageAlignment={block.imageAlignment}
      advancedImage={advancedImage}
      className="TextAndImageBlock"
    >
      <BlockContent value={block.text} />
    </LinkBanner>
  );
};
